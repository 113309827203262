import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SearchForm from '../SearchForm'
import { activities } from '../../Constants'

export default function SearchView(props)
{
	let image = 'beachvolleyball'
	if (props.state.searchFormValues['activity']) {
		switch (props.state.searchFormValues['activity'].value) {
			case activities.ACTIVITY_TENNIS:
				image = 'tennis'
				break
			default:
				image = 'beachvolleyball'
		}
	}
	return (
		<Container fluid id="view-1" className="view search" style={{backgroundImage: window.innerWidth > 991 ? 'url(images/' + image + '.jpg)' : ''}}>
			<Row className={'h-100 justify-content-center align-items-center'}>
				<Col className="col-12 col-lg-5 align-items-center search-col">
					<div className="search-wrapper">
						<h2>VYHLEDAT <span className="search-bold-text"> HŘIŠTĚ</span></h2>
						<SearchForm state={props.state}/>
					</div>
				</Col>
			</Row>
		</Container>
	)
}