import AsyncSelect from 'react-select/async'
import Col from 'react-bootstrap/Col'
import { config, searchFormDefaultValues } from '../Constants'
import { forwardRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import cs from 'date-fns/locale/cs'
registerLocale("cs", cs)

export default function SearchForm(props)
{
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isDesktopWidth = window.innerWidth >= 920

	const basicSelectStyles = {
		menu: (base, state) => ({
			...base,
			background: "#ffffff",
			color: "#000000",
			zIndex: 1000
		})
	}

	const getSelectOptionsActivities = async () => {
		const response = await fetch(config.url.GET_ACTIVITIES)
		return await response.json()
	}

	const getSelectOptionsHourRange = async () => {
		const response = await fetch(config.url.GET_HOUR_RANGES)
		return await response.json()
	}

	const getSelectOptionsDayRanges = async () => {
		const response = await fetch(config.url.GET_DAY_RANGES)
		return await response.json()
	}

	const getDefaultValue = (type) => {
		if (props.state.searchFormValues[type]) {
			return props.state.searchFormValues[type]
		} else {
			const defaultSearchFormTypeValues = searchFormDefaultValues[type]
			dispatch({type: 'SEARCH_FORM', key: type, data: defaultSearchFormTypeValues})
			return defaultSearchFormTypeValues
		}
	}

	const getDefaultDate = () => {
		if (props.state.searchFormValues['date']) {
			let savedDate = new Date(props.state.searchFormValues['date']['value'])
			let today = new Date()
			today.setUTCHours(0, 0, 0, 0)
			return savedDate.getTime() < today.getTime() ? today : savedDate
		} else {
			const date = new Date()
			dispatchPickedDate(date)
			return date
		}
	}

	const handleSelectActivityChange = (value) => {
		dispatch({type: 'SEARCH_FORM', key: 'activity', data: value})
	}

	const handleSelectHourRangeChange = (value) => {
		dispatch({type: 'SEARCH_FORM', key: 'hour_range', data: value})
	}

	const handleSelectDayRangeChange = (value) => {
		dispatch({type: 'SEARCH_FORM', key: 'day_range', data: value})
	}

	const handlePickDate = (date) => {
		setStartDate(date)
		dispatchPickedDate(new Date(date))
	}

	const dispatchPickedDate = (date) => {
		let month = (date.getMonth() + 1).toString()
		dispatch({
			type: 'SEARCH_FORM',
			key: 'date',
			data: {
				value: [date.getFullYear(), month.padStart(2, "0"), date.getDate()].join('-'),
				label: 'date'
			}
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		const values = props.state.searchFormValues
		let query = {}

		Object.keys(values).forEach(key => {
			query[key] = values[key]['value']
		})

		navigate({
			pathname: "results",
			search: `?${createSearchParams(query)}`
		})
	}

	const [startDate, setStartDate] = useState(getDefaultDate)
	const DatePickButton = forwardRef(({ value, onClick }, ref) => (
		<div className="date-pick-button" onClick={onClick} ref={ref}>
			{value}
		</div>
	));

	return (
		<form className="row search-form" id="searchForm" onSubmit={handleSubmit}>
			<Col className="col-12">
				<AsyncSelect
					name="activities"
					loadOptions={getSelectOptionsActivities} defaultOptions
					defaultValue={getDefaultValue('activity')}
					onChange={handleSelectActivityChange}
					styles={basicSelectStyles}
					isSearchable={isDesktopWidth}
				/>
			</Col>
			<Col className={['col-12', 'col-md-6']}>
				<label htmlFor="date">Den</label>
				<DatePicker
					locale="cs"
					dateFormat="dd. MM. yyyy"
					id="date"
					autoComplete="off"
					required
					className="form-control"
					selected={startDate}
					onChange={handlePickDate}
					minDate={new Date()}
					customInput={<DatePickButton />}
				/>
			</Col>
			<Col className={['col-12', 'col-md-6']}>
				<label htmlFor="hour_range">Délka</label>
				<AsyncSelect
					name="hour_range"
					placeholder="Vyberte"
					loadOptions={getSelectOptionsHourRange} defaultOptions
					defaultValue={getDefaultValue('hour_range')}
					onChange={handleSelectHourRangeChange}
					styles={basicSelectStyles}
					isSearchable={isDesktopWidth}
				/>
			</Col>
			<Col className={['col-12', 'col-md-6']}>
				<label htmlFor="day_range">Část dne</label>
				<AsyncSelect
					name="day_range"
					placeholder="Vyberte"
					loadOptions={getSelectOptionsDayRanges} defaultOptions
					defaultValue={getDefaultValue('day_range')}
					onChange={handleSelectDayRangeChange}
					styles={basicSelectStyles}
					isSearchable={isDesktopWidth}
				/>
			</Col>
			<div className="col-12">
				<button name="button" type="submit" value="submit" className="btn btn-search"><span>VYHLEDAT</span></button>
			</div>
		</form>
	)
}