import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Results from '../Results'
import { config } from '../../Constants'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function ResultsView(props)
{
	const [searchResults, setSearchResults] = useState({})
	const navigate = useNavigate()
	const [searchParams, ] = useSearchParams()

	useEffect(() => {
		let query = Object.fromEntries([...searchParams])
		getData(query).then((result) => {
			if (result === null) {
				navigate('/')
			}
			setSearchResults({results: result})
		})
	}, [searchParams, navigate])

	return (
		<Container fluid id="view-2" className="view results">
			<Row>
	 		    <Col>
	 				<Results data={searchResults.results} />
				 </Col>
	 		</Row>
		</Container>
	)
}

async function getData(request)
{
	const response = await fetch(config.url.SEARCH, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(request)
	})
	const data = await response.json()
	if (data.status !== undefined && data.status === 1 && data.data.length) {
		return data.data
	} else {
		return null
	}
}