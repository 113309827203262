import React from 'react';

const renderTableData = (data) => {
	const hours = filterData(data);
	return Object.keys(hours).map((key, index) => {
		let status = data[key] ? 'free' : 'occupied';

		let hours = Math.floor(key / 60).toString().padStart(2, '0');
		let minutes = (key % 60).toString().padStart(2, '0');
		let innerWidth = window.innerWidth;
		let delimiter = innerWidth <= 812 && innerWidth >= 500 ? ' ' : ':';
		return (
			<td key={index} className={status}>{`${hours}${delimiter}${minutes}`}</td>
		);
	})
}

const filterData = (values) => {
	let temp = {};
	Object.keys(values).forEach((key) => {
		if (key >= 420) {
			temp[key] = values[key];
		}
	});
	return temp;
};

export default function Table(props)
{
	return (
		// <div>
		// 	<h2 className={'court-title'}>{props.fieldName} #{props.data.court}</h2>
		// 	<div className={'court-table-wrapper'}>
		// 		<table className={'table court-table'} id={props.data.court}>
		// 			<tbody>
					<tr>
						<td className="firstColumn">{props.data.court}</td>
						{renderTableData(props.data.hours)}
					</tr>
		// 			</tbody>
		// 		</table>
		// 	</div>
		// </div>
	);
}