import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './reducers/ConfigureStore'
import { PersistGate } from 'redux-persist/integration/react'

import './css/bootstrap.min.css'
import './css/style.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App/>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

reportWebVitals()
