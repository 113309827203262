import { activities } from '../Constants'

const initialState = {
	searchFormValues: {},
	searchFormActivity: activities.ACTIVITY_BEACH
}

export default function rootReducer(state = initialState, action)
{
	switch (action.type) {
		case 'SEARCH_FORM':
			return {...state, searchFormValues: { ...state.searchFormValues, [action.key]: action.data }}
		default:
			return state;
	}
}