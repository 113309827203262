import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SearchView from './components/views/SearchView'
import ResultsView from './components/views/ResultsView'

export default function App()
{
	const state = useSelector((state) => state)
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<SearchView state={state}/>} />
				<Route path="/results" element={<ResultsView state={state}/>} />
				<Route path="*" element={<div>404 - Not found</div>} />
			</Routes>
		</BrowserRouter>
	)
}
