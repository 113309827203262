const prod = {
	url: {
		SEARCH: 'https://api.volnehriste.cz/search',
		GET_ACTIVITIES: 'https://api.volnehriste.cz/get-activities',
		GET_HOUR_RANGES: 'https://api.volnehriste.cz/get-hour-ranges',
		GET_DAY_RANGES: 'https://api.volnehriste.cz/get-day-ranges'
	}
}

const dev = {
	url: {
		SEARCH: 'https://api.volnehriste.l/search',
		GET_ACTIVITIES: 'https://api.volnehriste.l/get-activities',
		GET_HOUR_RANGES: 'https://api.volnehriste.l/get-hour-ranges',
		GET_DAY_RANGES: 'https://api.volnehriste.l/get-day-ranges'
	}
}

const ACTIVITY_BEACH = 1
const ACTIVITY_TENNIS = 2

export const config = process.env.NODE_ENV === 'development' ? dev : prod
export const colors = { purple: '#b24dff' }
export const activities = { ACTIVITY_BEACH, ACTIVITY_TENNIS }
export const searchFormDefaultValues = {
	activity: {value: 1, label: 'Beach volleyball'},
	hour_range: {value: 30, label: '30 minut'},
	day_range: {value: 0, label: 'Nerozhoduje'}
}