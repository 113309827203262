import React from 'react';
import Courts from './Courts'
import { Link } from 'react-router-dom'

export default function Results(props)
{
	if (props.data !== undefined) {
		return (
			<div className="inner-results">
				<Link to="/" className="search-back-button btn btn-dark">ZPĚT NA VYHLEDÁVÁNÍ</Link>
				<div className={'courts-wrapper'}>
					{props.data.map(result =>
						<Courts key={result.info.place} data={result} fieldName={result.info.fieldName} />
					)}
				</div>
			</div>
		)
	}
	return '';
}