import React from 'react';
import Table from './Table';

export default function Courts(props)
{
	return (
		<div>
			<h1 className="courts-title">{props.data.info.name} - {props.data.info.date}</h1>
			<div>
				{/*<h2 className={'court-title'}>{props.fieldName} #{props.data.court}</h2>*/}
				<div className={'court-table-wrapper'}>
					<table className={'table court-table'} id={props.data.court}>
						<tbody>
							{props.data.courts.map((result, index) =>
								<Table key={index} data={result} fieldName={props.fieldName}/>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}